import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layouts'
import * as ds from '../uiLibrary'
import { FeatureImage } from '../components';
import { StyledLink, A } from '../uiLibrary';
import * as theme from '../uiLibrary/constants'
import ReactMarkdown from 'react-markdown'
import HeadMeta from '../components/HeadMeta'

const AndroidVideoPage = ({ data }) => {
  const page = data.allAndroidVideoJson.edges[0].node
  return (
    <Layout>
      <HeadMeta
        pageTitle={page.pageTitle || 'Android Video - Tonit'}
        pageDescription={page.pageDescription}
        socialTitle={page.socialTitle}
        socialDescription={page.socialDescription}
        socialImage={page.socialImage}
      />
      <ds.Background bg={ds.colors.midnight}>
        <ds.FixedWidthRow>
          <ds.Column mt={theme.spacing.s4} span={9}>
            <ds.Headline>
              {page.heroHeading}
            </ds.Headline>
            <ReactMarkdown
              source={page.heroBody}
              renderers={{
                paragraph: props => <ds.P {...props} />,
                link: props => {
                  if (props.href.includes("mailto:") || props.href.includes("tel:")) {
                    return <A color={theme.colors.lightGray} {...props} />
                  } else {
                    return <StyledLink color={theme.colors.lightGray} {...props} />
                  }
                }
              }}
            />
          </ds.Column>
          <ds.Column mt={theme.spacing.s4} span={3}>
            <FeatureImage src={page.heroImage} maxWidth={["30%", "50%", "80%"]} pt={[0, theme.spacing.s4]} aria-hidden="true" />
          </ds.Column>
        </ds.FixedWidthRow>
      </ds.Background>
      <ds.Background bg={ds.colors.dusk}>
        <ds.FixedWidthRow>
          <ds.Column span={7}>
            <ds.H4 textAlign={['left', 'center']}>
              {page.ctaBody}
            </ds.H4>
          </ds.Column>
          <ds.Column vc span={5}>
            <StyledLink to={page.ctaButtonLink} alt={page.ctaButtonText}>
              <ds.Button>
                {page.ctaButtonText}
              </ds.Button>
            </StyledLink>
          </ds.Column>
        </ds.FixedWidthRow>
      </ds.Background>
    </Layout>
  )
}

export const query = graphql`
  query {
    allAndroidVideoJson {
      edges {
        node {
          heroHeading
          heroBody
          heroImage
          ctaBody
          ctaButtonText
          ctaButtonLink
          pageTitle
          pageDescription
          socialTitle
          socialDescription
          socialImage
        }
      }
    }
  }
`

export default AndroidVideoPage
